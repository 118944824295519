const firebaseConfig = {
  projectId: "wex-lofts-bdtlp",
  appId: "1:764454791142:web:9ac7805bdb5b701b975827",
  databaseURL:
    "https://wex-lofts-bdtlp-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "wex-lofts-bdtlp.appspot.com",
  locationId: "europe-west",
  apiKey: "AIzaSyD31caS7AGbJKauxMbHTFRHneTucmuZY9M",
  authDomain: "wex-lofts-bdtlp.firebaseapp.com",
  messagingSenderId: "764454791142",
  measurementId: "G-M7QXR4392N",
};

export default firebaseConfig;
